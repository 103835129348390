import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { faCircleCheck, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from '../Button/Button';
import "./Modal.scss";

/**
 * @readonly
 * @enum {number}
 */
export const MessageModalType = {
	Error: 1,
	Warning: 2,
	Information: 3,
	Question: 4
};

/**
 * Properties Object definition used in the Button component.
 * @typedef {Object} MessageModalProps
 * @property {string} title
 * @property {MessageModalType} type
 * @property {Function} onClick
 * @property {string} children
 */

/**
 * @component
 * @param {MessageModalProps} props
 */
const MessageModal = (props) => {
	const [visible, setVisible] = useState(true);

	const header = (
		<div><h1>{props.title}</h1></div>
	);

	const footer = (
		<>
			{props.type && props.type === MessageModalType.Question ?
				<div>
					<Button type={ButtonType.Primary} icon={faCheck} label={"Yes"} text={"Yes"} onClick={() => props.onClick(true)} />
					<Button type={ButtonType.Secondary} icon={faXmark} label={"No"} text={"No"} onClick={() => props.onClick(false)} />
				</div> :
				<div>
					<Button type={ButtonType.Primary} icon={faCircleCheck} label={"Ok"} onClick={props.onClick} />
				</div>
			}
		</>
	);
	

	useEffect(() => {
		if (visible) {
			document.body.classList.add("body-no-scroll");
		} else {
			document.body.classList.remove("body-no-scroll");
		}
		return () => {
			document.body.classList.remove("body-no-scroll");
		};
	}, [visible]);

	return (
		<Dialog id="uc-modal" position="center" closable={false} visible={visible} header={header} footer={footer} onHide={() => setVisible(false)}>
			<hr />
			<div className="body font_s">
				{props.children}
			</div>
			<hr />
		</Dialog>
	);
};

export default MessageModal;
