import { useState, useEffect } from 'react';
import './ModalContent.scss';
import { Button, ButtonType } from 'components/Button/Button';
import useFetchAuto from 'hooks/useFetchAuto';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getFetchOptions } from 'utils/fetch';
import useFetch from 'hooks/useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const ModalContent = ({ edit, companyId, users = [], onClose }) => {
	const { loading, data } = useFetchAuto(`/api/CompanyUser/CreationPrerequisites/${companyId}/-99`);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		username: '',
		roleId: 0,
		contentGroups: [],
		deviceGroup: null,
	});
	const [initialUsers, setInitialUsers] = useState([]);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const { error, execute } = useFetch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [deviceGroupUserCount, setDeviceGroupUserCount] = useState({});
	const [contentGroupUserCount, setContentGroupUserCount] = useState({});

	const contentGroupOptions = [
		{ "Id": 101, "Name": "Admin Group" },
		{ "Id": 102, "Name": "User Group" },
		{ "Id": 103, "Name": "HR Group" },
		{ "Id": 104, "Name": "IT Group" },
		{ "Id": 105, "Name": "New Starters" },
		{ "Id": 106, "Name": "Management" }
	];

	const deviceGroupOptions = [
		{ "Id": 101, "Name": "All Devices" },
		{ "Id": 102, "Name": "Yealink T4 Series" },
		{ "Id": 103, "Name": "Sales Department" },
	];

	useEffect(() => {
		if (edit && users.length > 0) {
			setInitialUsers(users.map(user => ({ ...user })));
			setUpdatedUsers(users.map(user => ({ ...user })));
			if (users.length === 1) {
				const user = users[0];
				setFormData({
					firstName: user.FullName ? user.FullName.split(' ')[0] : '',
					lastName: user.LastName || '',
					username: user.Username || '',
					roleId: user.RoleId || 0,
					contentGroups: user.ContentGroups.map(group => ({ Id: group.Id, Name: group.Name })) || [],
					deviceGroup: user.DeviceGroups?.[0] || null
				});
			} else {
				const accessLevels = users.map(user => user.RoleId);
				const uniqueAccessLevels = [...new Set(accessLevels)];

				const mixedAccessLevel = (uniqueAccessLevels.length === 1) ? uniqueAccessLevels[0] : -1;
				
				const uniqueDeviceGroups = users
					.map(user => user.DeviceGroups[0]?.Id)
					.every(id => id === users[0].DeviceGroups[0]?.Id);

				const contentGroupCount = users.reduce((acc, user) => {
					user.ContentGroups.forEach(group => {
						acc[group.Id] = (acc[group.Id] || 0) + 1;
					});
					return acc;
				}, {});

				const updatedContentGroups = contentGroupOptions.map(group => {
					const userCount = contentGroupCount[group.Id] || 0;
					if (userCount === users.length) {
						// Fully selected
						return { Id: group.Id, Name: group.Name };
					} else if (userCount > 0) {
						// Partially selected
						return { Id: group.Id, Name: group.Name, mixed: true };
					}
					// Not selected
					return null;
				}).filter(Boolean);

				setFormData({
					firstName: '',
					lastName: '',
					username: '',
					roleId: mixedAccessLevel,
					contentGroups: updatedContentGroups,
					deviceGroup: uniqueDeviceGroups ? users[0].DeviceGroups?.[0] : null,
				});
			}
		} else if (!edit && data?.RoleData?.length > 0 && formData.roleId === 0) {
			// Only update the formData if it's not already set
			setFormData(prevData => ({
				...prevData,
				roleId: data.RoleData[0].Id,
				contentGroups: []
			}));
		}

		const groupCount = users.reduce((acc, user) => {
			const deviceGroup = user.DeviceGroups?.[0]?.Id;
			if (deviceGroup) {
				acc[deviceGroup] = (acc[deviceGroup] || 0) + 1;
			}
			return acc;
		}, {});
		setDeviceGroupUserCount(groupCount);

		const contentGroupCount = users.reduce((acc, user) => {
			user.ContentGroups.forEach(group => {
				acc[group.Id] = (acc[group.Id] || 0) + 1;
			});
			return acc;
		}, {});
		setContentGroupUserCount(contentGroupCount);

		if (edit) {
			const updatedContentGroups = contentGroupOptions.map(group => {
				const userCount = contentGroupCount[group.Id] || 0;
				if (userCount === users.length) {
					return { Id: group.Id, Name: group.Name };
				} else if (userCount > 0) {
					return { Id: group.Id, Name: group.Name, mixed: true };
				}
				// Not selected
				return null;
			}).filter(Boolean);

			setFormData(prevData => ({
				...prevData,
				contentGroups: updatedContentGroups
			}));
		}

	}, [users, edit, data, formData.roleId]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'roleId' && formData.roleId === -1) {
			return;
		}
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleCheckboxChange = (e, group, groupType) => {
		const { checked } = e.target;
		setFormData((prevData) => {
			let updatedGroups;
			if (checked) {
				updatedGroups = [...prevData[groupType], { Id: group.Id, Name: group.Name }];
			} else {
				updatedGroups = prevData[groupType].filter(g => g.Id !== group.Id);
			}
			return { ...prevData, [groupType]: updatedGroups };
		});
	};

	const handleRadioChange = (e, group) => {
		setFormData((prevData) => ({
			...prevData,
			deviceGroup: { Id: group.Id, Name: group.Name }
		}));
	};

	const handleIconClick = (group) => {
		alert(`Device Group ID: ${group.Id}, Name: ${group.Name}`);
	};

	const handleDeviceGroupClick = () => {
		alert("Edit Device");
	};

	const handleDeleteClick = () => {
		// Delete logic
	};

	const handleResendClick = () => {
		// Resend registration logic
	};

	const handleSubmitClick = () => {
		if (edit) {
			const finalUsers = updatedUsers.map(user => {
				const updatedUser = { ...user };
				if (users.length === 1) {
					updatedUser.FirstName = formData.firstName;
					updatedUser.LastName = formData.lastName;
					updatedUser.Username = formData.username;
					updatedUser.RoleId = formData.roleId;
				}

				const initialUser = initialUsers.find(u => u.Id === user.Id);
				const initialContentGroups = initialUser.ContentGroups.map(group => group.GroupName);
				updatedUser.ContentGroups = initialUser.ContentGroups.filter(group =>
					!contentGroupOptions.includes(group.Name) || formData.contentGroups.some(fg => fg.Name === group.Name)
				);

				formData.contentGroups.forEach(group => {
					if (!initialContentGroups.includes(group.Name)) {
						updatedUser.ContentGroups.push({ GroupId: group.Id, GroupName: group.Name });
					}
				});

				updatedUser.DeviceGroups = [formData.deviceGroup];

				return {
					Id: updatedUser.Id,
					CompanyId: companyId,
					FirstName: updatedUser.FullName ? updatedUser.FullName.split(' ')[0] : '',
					LastName: updatedUser.LastName,
					Username: updatedUser.Username,
					RoleId: formData.roleId === -1 ? updatedUser.RoleId : formData.roleId,
					CreatedOnRegistration: false,
					Active: true,
					ContentGroups: updatedUser.ContentGroups,
					DeviceGroups: updatedUser.DeviceGroups
				};
			});
			setIsSubmitting(true);
			const options = getFetchOptions({
				method: "PUT",
				body: {
					Users: finalUsers
				}
			});
			execute("/api/CompanyUser/", options, (err) => {
				setIsSubmitting(false);
				if (err && err.UnsuccessfulUpserts.length === 0) {
					alert('Users updated successfully');
					if (onClose) onClose();
				} else {
					const failedUpserts = err.UnsuccessfulUpserts.map(user => `Username: ${user.Username}, Reason: ${user.Message}`).join('\n');
					alert('The following users did not update successfully.' + failedUpserts);
					if (onClose) onClose();
				}
			});
		}

		if (!edit) {
			setIsSubmitting(true);
			const options = getFetchOptions({
				method: "PUT",
				body: {
					SingleUser: {
						Id: -1,
						CompanyId: companyId,
						FirstName: formData.firstName,
						LastName: formData.lastName,
						Username: formData.username,
						RoleId: formData.roleId,
						CreatedOnRegistration: false,
						Active: true,
						ContentGroups: formData.contentGroups,
						DeviceGroups: [formData.deviceGroup]
					}
				}
			});
			execute("/api/CompanyUser/", options, (err) => {
				setIsSubmitting(false);
				if (err.Status === 200) {
					alert('User added successfully');
					if (onClose) onClose();
				} else {
					alert('User could not be added');
				}
			});
		}
	};

	const contentGroupsClass = contentGroupOptions.length > 5 ? "overflowed" : "";
	const deviceGroupsClass = deviceGroupOptions.length > 5 ? "overflowed" : "";

	return (
		<>
			<div className="user-management-modal">
				{loading || isSubmitting
					? <LoadingSpinner active={loading} />
					: <form>
						<div className="user-management-modal-grid">
							{users.length < 2 && <>
								<div>
									<label>
										<h4>First Name</h4>
										<input
											type="text"
											name="firstName"
											value={formData.firstName}
											onChange={handleChange}
											disabled={edit && users.length > 1}
											required={!edit}
											placeholder={edit && users.length > 1 ? '-' : ''}
										/>
									</label>
								</div>
								<div>
									<label>
										<h4>Last Name</h4>
										<input
											type="text"
											name="lastName"
											value={formData.lastName}
											onChange={handleChange}
											disabled={edit && users.length > 1}
											required={!edit}
											placeholder={edit && users.length > 1 ? '-' : ''}
										/>
									</label>
								</div>
								<div>
									<label>
										<h4>Email</h4>
										<input
											type="email"
											name="username"
											value={formData.username}
											onChange={handleChange}
											disabled={edit}
											required={!edit}
											placeholder={edit && users.length > 1 ? '-' : ''}
										/>
									</label>
								</div>
							</>}
							<div>
								<label>
									<h4>Access Level</h4>
									<select
										name="roleId"
										value={formData.roleId}
										onChange={handleChange}
										required
										disabled={formData.roleId === -1}
									>
										{data?.RoleData?.map(role => (
											<option key={role.Id} value={role.Id}>{role.Name}</option>
										))}
										{formData.roleId === -1 && (
											<option value={-1} disabled>Mixed</option>
										)}
									</select>
								</label>
							</div>
							<div className={`user-management-modal-grid-colOneSpanTwo checkbox-list`}>
								<label><h4>Content Groups</h4></label>
								<div className={`${contentGroupsClass}`}>
									{contentGroupOptions.map((group) => {
										const isPresentForSome = users.some(user => (user.ContentGroups || []).map(g => g.Id).includes(group.Id));
										const isPresentForAll = users.every(user => (user.ContentGroups || []).map(g => g.Id).includes(group.Id));

										const isMixed = isPresentForSome && !isPresentForAll;
										const isChecked = formData.contentGroups.some(g => g.Id === group.Id);

										return (
											<div key={group.Id}>
												<label>
													<p className={`contentGroup ${isMixed ? 'mixed' : ''}`}>
														<input
															type="checkbox"
															name={group.Name}
															checked={isChecked}
															onChange={(e) => handleCheckboxChange(e, group, 'contentGroups')}
														/>
														{group.Name} {contentGroupUserCount[group.Id] > 0 && <>({contentGroupUserCount[group.Id]} users)</>}
													</p>
												</label>
											</div>
										);
									})}
								</div>
							</div>
							<div className="user-management-modal-grid-colTwoSpanTwo checkbox-list">
								<label><h4>Device Groups</h4></label>
								<div className={deviceGroupsClass}>
									{deviceGroupOptions.map((group) => {
										const isChecked = formData.deviceGroup?.Id === group.Id;
										const userCount = deviceGroupUserCount[group.Id] || 0;

										return (
											<div key={group.Id}>
												<label>
													<p className="contentGroup">
														<input
															type="radio"
															name="deviceGroup"
															checked={isChecked}
															onChange={(e) => handleRadioChange(e, group)}
														/>
														{group.Name} {userCount > 0 && <>({userCount} users)</>}
														<FontAwesomeIcon
															icon={faPenToSquare}
															onClick={() => handleIconClick(group)}
															className="edit-icon"
															style={{ marginLeft: '10px', cursor: 'pointer' }}
														/>
													</p>
												</label>
											</div>
										);
									})}
								</div>
								<Button type={ButtonType.Tertiary} text="Add New Device Group" onClick={handleDeviceGroupClick} />
							</div>
						</div>
					</form>
				}
			</div>
			<div className="modal-buttons">
				{edit && <>
					<Button type={ButtonType.Delete} text={`Delete ${users.length} Users`} onClick={handleDeleteClick} />
					<Button type={ButtonType.Secondary} text="Resend Registration" onClick={handleResendClick} />
				</>}
				<Button type={ButtonType.Primary} text="Save" onClick={handleSubmitClick} />
			</div>
		</>
	);
};

export default ModalContent;
