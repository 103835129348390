import { useEffect } from "react";
import useFetch from "./useFetch";

/**
 * @typedef {Object} FetchState
 * @property {boolean} executing
 * @property {{status: number, message: string}} error
 * @property {string} data
 */

/**
 * This hook wraps the useFetch hook to perform requests automatically, typically GET requests on page renders.
 * @returns {FetchState} State data.
 */
const useFetchAuto = (url, options, fetch = true) => {
  const { data, error, execute, executing } = useFetch();
  useEffect(() => {
    if (fetch) {
      execute(url, options);
    }
  }, [url, options, fetch]); // Missing dependency for "execute" here - which causes an infinite loop when included

  return {
    executing,
    error,
    data
  };
};

export default useFetchAuto;