import { faChevronRight, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Button.scss";

const ButtonType = {
	Primary: 1,
	Secondary: 2,
	Tertiary: 3,
	Delete: 4,
};

const Button = ({
	text = 'Button',
	type = ButtonType.Primary,
	to = null,
	flipped = false,
	state = null,
	onClick = null,
	disabled = false,
	disableUntilClickCompleted = false,
	newTab = false,
}) => {
	const [handlingClickEvent, setHandlingClickEvent] = useState(false);

	const typeMap = {
		[ButtonType.Primary]: "button-primary",
		[ButtonType.Secondary]: "button-secondary",
		[ButtonType.Tertiary]: "button-tertiary",
		[ButtonType.Delete]: "button-delete"
	};
	let buttonClass = typeMap[type];

	const flippedClass = flipped || buttonClass === "button-delete" ? "flipped" : "";

	// Prevent multiple clicks while previous click is processing.
	const handleClick = (e) => {
		if (handlingClickEvent) {
			return;
		}
		setHandlingClickEvent(true);
		if (disableUntilClickCompleted) {
			onClick && onClick(e, setHandlingClickEvent); // Ensure onClick is a function before calling it
		} else {
			onClick && onClick(e); // Ensure onClick is a function before calling it
			setHandlingClickEvent(false);
		}
	};

	const disabledClass = (disabled || handlingClickEvent) ? "disabled" : "";

	const ButtonContent = (
		<>
			<span className="button-text">{text}</span>
			<span className="button-arrow">
				<span className="button-arrow-icon">
					{buttonClass !== "button-delete" && <FontAwesomeIcon icon={faChevronRight} />}
					{buttonClass === "button-delete" && <FontAwesomeIcon icon={faTrashCan} />}
				</span>
			</span>
		</>
	);

	if (to) {
		return (
			<Link to={to} state={state} className={`button ${buttonClass} ${flippedClass} ${disabledClass}`} target={newTab ? '_blank' : '_self'}>
				{ButtonContent}
			</Link>
		);
	}
	return (
		<div className={`button ${buttonClass} ${flippedClass} ${disabledClass}`} onClick={handleClick}>
			{ButtonContent}
		</div>
	);
};

export { Button, ButtonType };
