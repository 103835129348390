import React, { createContext, useState, useRef, useContext } from 'react';

const ModalContext = createContext();

///////////////USAGE////////////////

//const { openModal } = useModal();

//const modalTitle = "Unlock Modules"
//const modalBasicButton = true;

//const modalContent = (
//	<div>
//		<p>Modal Content</p>
//		<div className="modal-buttons">
//			{ your custom buttons (remember to set the basic buttons to false) }
//		</div >
//	</div>
//);

//const handleOpenModal = () => {
//	openModal(modalTitle, modalContent, modalBasicButton);
//};

export const ModalProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [modalTitle, setModalTitle] = useState(null);
	const [modalBasicButton, setModalBasicButton] = useState(true);
	const handleCustomCloseModal = useRef(null);

	const openModal = (title, content, basicButton, onCloseModal) => {
		setModalTitle(title);
		setModalContent(content);
		setModalBasicButton(basicButton);
		setIsOpen(true);
		handleCustomCloseModal.current = onCloseModal;
	};

	const closeModal = () => {
		setIsOpen(false);
		setModalContent(null);
		setModalTitle(null);
		setModalBasicButton(true);
		if (handleCustomCloseModal.current) {
			handleCustomCloseModal.current();
		}
	};

	return (
		<ModalContext.Provider value={{ isOpen, openModal, closeModal, modalContent, modalTitle, modalBasicButton }}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModal = () => useContext(ModalContext);
