import { faCircleQuestion, faPersonChalkboard, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "assets/images/ucskills-logo.svg";
import useLogout from "hooks/useLogout";
import useUserData from "hooks/useUserData";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "../Search/SearchBox/SearchBox";
import "./Header.scss";

const Header = () => {
	const [sidebarToggle, setSidebarToggle] = useState(false);
	const [newPrimaryHex, setNewPrimaryHex] = useState('#143872');
	const [newSecondaryHex, setNewSecondaryHex] = useState('#f68b1f');
	const navigate = useNavigate();
	const { logout } = useLogout(navigate);
	const { userData } = useUserData();
	const guruUrls = (sessionStorage.gurus) ? JSON.parse(sessionStorage.gurus) : [];

	useEffect(() => {
		const brandingItem = sessionStorage.getItem("branding");
		if (brandingItem && brandingItem[0] === '{') {
			const branding = JSON.parse(brandingItem);
			setNewPrimaryHex('#' + branding.PrimaryColour);
			setNewSecondaryHex('#' + branding.SecondaryColour);
		}
	}, []);

	const toggleSidebar = () => {
		setSidebarToggle(!sidebarToggle);
	};

	const handleSearch = (searchValue) => {
		navigate("/searchresults", { state: searchValue });
	};

	const handleLogout = () => {
		logout();
	};

	const renderGuruUrls = () => {
		if (!guruUrls.length) {
			return null;
		}
		if (guruUrls.length === 1) {
			return (
				<div className="sidebar-links-link">
					<FontAwesomeIcon icon={faPersonChalkboard} />
					<Link className="sidebar-links-link-text" to={guruUrls[0].GuruUrl} onClick={toggleSidebar} target="_blank">
						Book a Guru
					</Link>
				</div>
			);
		}

		return (guruUrls.map(guru => (
			<div key={guru.Id} className="sidebar-links-link">
				<FontAwesomeIcon icon={faPersonChalkboard} />
				<Link className="sidebar-links-link-text" to={guru.GuruUrl} onClick={toggleSidebar} target="_blank">
				Book a {guru.Name} Guru
				</Link>
			</div>
		)));
	};

	return (
		<>
			<div className="header">
				<style>
					{`:root { 
					--primary-custom-color: ${newPrimaryHex}; 
					--secondary-custom-color: ${newSecondaryHex};
					}`}
				</style>
				<div className="header-logo">
					<Link to="/dashboard" >
						<img alt="UCSkills" src={logo} />
					</Link>
				</div>

				<div className="header-search no-mobile">
					<SearchBox handleClick={handleSearch} />
				</div>

				<div className={`header-hamburger ${sidebarToggle ? 'header-hamburger-open' : ''}`} onClick={toggleSidebar}>
					<span className="header-hamburger-bar header-hamburger-bar-one"></span>
					<span className="header-hamburger-bar header-hamburger-bar-two"></span>
					<span className="header-hamburger-bar header-hamburger-bar-three"></span>
				</div>

			</div>
			<div className={`sidebar ${sidebarToggle ? 'sidebar-open' : ''}`}>
				<h3>{userData.Name}</h3>
				<p>{userData.Email}</p>
				<p>{userData.Company}</p>
				<div className="sidebar-links">
					<div className="sidebar-links-link">
						<FontAwesomeIcon icon={faCircleQuestion} />
						<Link className="sidebar-links-link-text" to="/help" onClick={toggleSidebar}>
							Help
						</Link>
					</div>
					{renderGuruUrls()}
					<div className="sidebar-links-link">
						<FontAwesomeIcon icon={faUser} />
						<Link className="sidebar-links-link-text" to="/profile" onClick={toggleSidebar}>
							Profile Management
						</Link>
					</div>
					<div className="sidebar-links-link">
						<FontAwesomeIcon icon={faRightFromBracket} className="secondary" />
						<Link className="sidebar-links-link-text secondary" onClick={() => { toggleSidebar(); handleLogout(); } }>
							Sign Out
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default Header;
