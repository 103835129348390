import { Outlet } from "react-router-dom";
import Header from "../features/Header/Header";
import BackToTop from "components/BackToTop/BackToTop"

const Layout = () => {
	return (
		<>
			<Header />
			<Outlet />
			<BackToTop />
		</>
	);
};

export default Layout;
