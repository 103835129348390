import { Button, ButtonType } from "components/Button/Button";
import SearchBox from "../SearchBox/SearchBox";
import { useNavigate } from "react-router-dom";
import "./QuickSearch.scss";

const QuickSearch = ({ bentobox=false }) => {
	const navigate = useNavigate();

	const searchTerms = [
		{
			"Name": "Cloud Voice",
			"URL": "",
		},
		{
			"Name": "Call Forwarding",
			"URL": "",
		},
		{
			"Name": "Greetings",
			"URL": "",
		},
		{
			"Name": "Gurus",
			"URL": "",
		},
		{
			"Name": "Hunt Groups",
			"URL": "",
		}
	];
	
	const handleSearch = (searchValue) => {
		navigate("/searchresults", { state: searchValue });
	};

	return (
		<div className={`quickSearch ${bentobox ? "bentobox" : ""}`}>
			<SearchBox handleClick={handleSearch} />
			<h2>Quick Search</h2>
			<div className="quickSearch-list">
			{searchTerms.map(link => (
				<div key={link.Name} className="listButton">
					<Button to="/searchresults" state={link.Name} text={link.Name} type={ButtonType.Secondary} />
				</div>
				))}
			</div>
		</div>
	);
};

export default QuickSearch;
