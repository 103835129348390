import { useEffect } from "react";
import { useModal } from "./context/ModalContext";
import './Modal.scss'; 
import useBranding from "hooks/useBranding";
import waterfall from "../../assets/images/waterfall.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonType } from "components/Button/Button";

///////////////USAGE////////////////

//const { openModal } = useModal();

//const modalTitle = "Unlock Modules"
//const modalBasicButton = true;

//const modalContent = (
//	<div>
//		<p>Modal Content</p>
//		<div className="modal-buttons">
//			{ your custom buttons (remember to set the basic buttons to false) }
//		</div >
//	</div>
//);

//const handleOpenModal = () => {
//	openModal(modalTitle, modalContent, modalBasicButton);
//};

const Modal = () => {
	const { isOpen, closeModal, modalContent, modalTitle, modalBasicButton } = useModal();
	const { gradient, watermark } = useBranding();

	let watermarkSource = waterfall;
	if (watermark) {
		if (watermark.src) {
			watermarkSource = watermark.src;
		} else if (!watermark.revertToDefault) {
			watermarkSource = null;
		}
	}

	// Prevent page scrolling when the modal is open
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll');
		} else {
			document.body.classList.remove('no-scroll');
		}

		// Cleanup when the component is unmounted
		return () => {
			document.body.classList.remove('no-scroll');
		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<div className="modal">
			<div className="modal-backdrop" onClick={closeModal}></div>
			<div className="modal-container">
				<div className="modal-header">
					<h1>{modalTitle}</h1>
					{watermarkSource && <img alt="Pod Background Detail" className="podHeader-image" src={watermarkSource} />}
					{gradient && <span className="podHeader-grad"></span>}
					<button className="modal-close" onClick={closeModal}><FontAwesomeIcon icon={faXmark} /></button>
				</div>
				<div className="modal-content">
					{modalContent}
					{modalBasicButton && <div className="modal-buttons"><Button type={ButtonType.Secondary} text="Ok" onClick={closeModal} /></div>}
				</div>
			</div>
		</div>
	);
};

export default Modal;
