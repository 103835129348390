import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { PackageType, packageIsScorm } from "features/Shared/ContentData/PackageType";
import useFetch from "hooks/useFetch";
import { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { getFetchOptions } from "utils/fetch";
import "./Player.scss";
import PlayerBreadcrumb from "./components/PlayerBreadcrumb";
import PlayerDetails from "./components/PlayerDetails";
import PlayerMedia from "./components/PlayerMedia";
import PlayerSidebar from "./components/PlayerSidebar";
import useMessageReceiver from "./hooks/useMessageReceiver";
import { useModal } from "components/Modal/context/ModalContext";

const Player = () => {
	const mediaRef = useRef();
	const [data, setData] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();
	const { execute, executingFetch } = useFetch();
	const [expectedMessageOrigin, setExpectedMessageOrigin] = useState(null);
	const [allSections, setAllSections] = useState(null);
	const [sidebarSections, setSidebarSections] = useState(null);
	const [selectedSection, setSelectedSection] = useState(0);
	const [detailNavigationSections, setDetailNavigationSections] = useState({});
	const [breadcrumbHeight, setBreadcrumbHeight] = useState(0);
	const [originalPlayerHeight, setOriginalPlayerHeight] = useState();
	const [originalPlayerWidth, setOriginalPlayerWidth] = useState();
	const [calculatedPlayerHeight, setCalculatedPlayerHeight] = useState("100%");
	const [calculatedPlayerWidth, setCalculatedPlayerWidth] = useState("100%");
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const { openModal } = useModal();
	
	const unloading = useRef(0);
	let blocker = useBlocker(({ currentLocation, nextLocation }) => data && data.Path.length && currentLocation.pathname !== nextLocation.pathname);
	
	const handleCloseModal = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	useEffect(() => {
		if (location.hash) {
			const idList = location.hash.slice(1).split('-'); // Format is #courseId-*-moduleId-*-subModuleId-*
			execute(`/api/PlayerData/${idList[3]}/${idList[5]}`, null, (newData, error) => {
				if (error) {
					console.error(error.message);
					handleNoContentModal();
				}
				else {
					setData(newData);
				}
			});
		}
	}, [location, execute]);

	const handleNoContentModal = useCallback(() => {
		const modalContent = (<p>This content is not yet available.</p>);
		openModal("No Content", modalContent, true, handleCloseModal);
	}, [handleCloseModal]); // Adding openModal here causes the modal to show twice

	useEffect(() => {
		if ((!executingFetch) && (data)) {
			console.log("useEffect : data gathered");
			if (!data.Path) {
				handleNoContentModal();
				return;
			}
			if (data.TableOfContents.length) {
				const all = JSON.parse(data.TableOfContents);
				setAllSections(all);
				const filteredSections = all.filter((curr) => {
					return ((curr.title.toLocaleLowerCase() !== "completed") && (!curr.title.toLocaleLowerCase().startsWith("slide")));
				});
				setSidebarSections(filteredSections);
				setDetailNavigationSections({ next: filteredSections[0] });
			}
			else {
				setSidebarSections([]);
				setDetailNavigationSections({});
			}
			setExpectedMessageOrigin(new URL(data.Path).origin);
		}
	}, [executingFetch, data, handleNoContentModal]);

	const handleSelectSection = (id) => {
		setSelectedSection(id);
		const index = sidebarSections.findIndex((curr) => {
			return curr.id === id;
		});
		setDetailNavigationSections({
			prev: index > 0 ? sidebarSections[index - 1] : null,
			next: index < sidebarSections.length - 1 ? sidebarSections[index + 1] : null
		});
	};

	const handleClickSectionItem = (id) => {
		const index = allSections.findIndex((curr) => {
			return curr.id === id;
		});
		if (index !== -1) {
			handleSelectSection(allSections[index].id);
			handlePostMessage({ action: data.Type === PackageType.xAPI_CAPTIVATE ? "setSlide" : "setSection", no: index });
		}
	}

	const handleMediaLoaded = () => {
		if (packageIsScorm(data.Type)) {
			return;
		}

		// Hide the TOC.
		handlePostMessage({ action: "hideToc" });

		// Request container dimensions.
		handlePostMessage({ action: "getDimensions" });

		// Highlight last accessed slide number.
		if (allSections) {
			const index = allSections.findIndex((curr) => {
				return curr.title === data.Current;
			});
			if (index !== -1) {
				handleSelectSection(allSections[index].id);
			}
		}
	}

	const handleSlideEnter = (messageData) => {
		console.debug("ucSKILLS::Recieved", messageData.eventName);
		let index = -1;
		if (data.Type === PackageType.xAPI_CAPTIVATE) {
			index = allSections.findIndex((curr) => {
				return curr.title === messageData.slideName;
			});
		}
		else if (messageData.sectionData) {
			index = allSections.findIndex((curr) => {
				return Number(curr.id) === messageData.sectionData.Id;
			});
		}
		if (index !== -1) {
			handleSelectSection(allSections[index].id);
		}
	};

	const handleReceiveDimensions = (messageData) => {
		setOriginalPlayerHeight(messageData.boundingRect.height);
		setOriginalPlayerWidth(messageData.boundingRect.width);
	};

	const executeXapiTermination = useCallback((messageData, blocker) => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				Module: data.Module,
				SubModule: data.SubModule,
				SectionData: {
					Name: messageData.sectionName || "",
					Ids: messageData.sectionIds || ""
				},
				Duration: messageData.Duration || 0,
				Ranges: messageData.Ranges || []
			}
		});

		// This seems to run twice, even with the preventative measures (without these, it runs several times).
		if (!executingFetch && unloading.current === 0) {
			++unloading.current;
			execute("/api/Xapi/PutTerminate", options, (result, error) => {
				if (blocker.state === "blocked") {
					blocker.proceed();
					--unloading.current;
				}
			});
		}
	}, [data, blocker, executingFetch]);

	const executeScormTermination = useCallback((messageData, blocker) => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				Id: messageData.Id,
				Property: "cmi.core.exit",
				Value: messageData.Value
			}
		});
		if (!executingFetch && unloading.current === 0) {
			++unloading.current;
			execute("/api/statements/cmi", options, (result, error) => {
				if (blocker.state === "blocked") {
					blocker.proceed();
					--unloading.current;
				}
			});
		}
	}, [data, blocker, executingFetch]);

	const postTerminate = useCallback((messageData) => {
		if (!packageIsScorm(data.Type)) {
			executeXapiTermination(messageData, blocker);
		}
		else {
			executeScormTermination(messageData, blocker);
		}
	}, [data, blocker]);

	const handlePostMessage = useCallback((messageData) => {
		const path = (packageIsScorm(data.Type)) ? data.ScormData.ProxyUri : data.Path;
		const destination = mediaRef && mediaRef.current;
		if (destination && destination.contentWindow) {
			console.debug("Sending message:", messageData.action, messageData);
			console.debug("Posting message to:", path);
			destination.contentWindow.postMessage(JSON.stringify(messageData), path);
		}
		else {
			console.warn("MediaRef or destination contentWindow is null");
		}
	}, [mediaRef, data]);

	// On exiting the page...
	useEffect(() => {
		if (blocker.state === "blocked") {
			if (data.Type !== PackageType.xAPI_CAPTIVATE) {
				// Xapi and SCORM send unload messages; the response data is used to write LRS termination events.
				handlePostMessage({ action: "unload", v3: true });
			}
			else {
				// Captivate has no unload communication.
				postTerminate({});
			}
		}
	}, [blocker.state, handlePostMessage, postTerminate, data]);

	const messageMap = {
		"CPAPI_SLIDEENTER": handleSlideEnter,
		"SECTIONSTART": handleSlideEnter,
		"DIMENSIONS": (messageData) => {
			handleReceiveDimensions(messageData);
		},
		"UNLOAD": postTerminate
	};

	useMessageReceiver(expectedMessageOrigin, messageMap, unloading);

	useEffect(() => {
		const handleResize = () => {
			setViewportHeight(window.innerHeight);
			setViewportWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const calculatePlayerSize = () => {
		const element = document.getElementById('player-container');

		// Detect if the viewport width is below 1024px (tablet/mobile view)
		if (viewportWidth < 1024) {
			// Mobile/Tablet view: always set width to "unset"
			setCalculatedPlayerWidth("unset");

			// Use requestAnimationFrame to ensure DOM has updated
			requestAnimationFrame(() => {
				if (element) {
					const computedWidth = element.offsetWidth; // Get the actual width from the DOM

					// Calculate height based on playerRatio and the actual computed width
					if (originalPlayerWidth && originalPlayerHeight) {
						const playerRatio = originalPlayerHeight / originalPlayerWidth; // Aspect ratio of the player
						const newHeight = computedWidth * playerRatio; // Calculate height using the player ratio (note it's computedWidth * playerRatio)
						setCalculatedPlayerHeight(newHeight); // Set the new height
					} else {
						// Fallback if the player ratio is not available
						setCalculatedPlayerHeight("auto");
					}
				}
			});
		} else {
			// Desktop view: calculate both width and height as per the existing logic
			const detailsHeight = 100; // Area below the player with the navigation
			const headerHeight = 107; // Header of the page
			const bottomPadding = 10; // Leave a gap at the bottom
			const breadcrumb = 88;
			const menu = 430; // Section Menu
			const sidePadding = 60; // At least 30px each side

			const newMaxHeight = viewportHeight - (detailsHeight + headerHeight + bottomPadding + breadcrumb);
			const newMaxWidth = viewportWidth - (menu + sidePadding);

			if (originalPlayerWidth && originalPlayerHeight) {
				const playerRatio = originalPlayerHeight / originalPlayerWidth; // Aspect ratio of the player

				// Calculate new width and height based on max width and height
				let newWidth = newMaxWidth;
				let newHeight = newWidth * playerRatio;

				// If new height exceeds max height, adjust width and height accordingly
				if (newHeight > newMaxHeight) {
					newHeight = newMaxHeight;
					newWidth = newHeight / playerRatio;
				}

				// Set the calculated width and height
				setCalculatedPlayerWidth(newWidth);
				setCalculatedPlayerHeight(newHeight);
			} else {
				setCalculatedPlayerHeight(newMaxHeight + detailsHeight);
			}
		}
	};

	// Calculating Player Dimensions
	useEffect(() => {
		calculatePlayerSize();
	}, [originalPlayerHeight, originalPlayerWidth, viewportHeight, viewportWidth, breadcrumbHeight, calculatedPlayerWidth]);

	if ((!data) || (!sidebarSections) || (!expectedMessageOrigin)) {
		return <LoadingSpinner />;
	}

	return (
		<div className={`player ${sidebarSections.length > 0 ? 'withSidebar' : 'withoutSidebar'}`}>
			<div id="player-container" className="player-container" style={{ width: calculatedPlayerWidth }}>
				<PlayerBreadcrumb
					course={data.Course}
					module={data.Module}
					submodule={data.SubModule.Name}
					setBreadcrumbHeight={setBreadcrumbHeight}
				/>
				<PlayerMedia
					data={data}
					mediaRef={mediaRef}
					handleMediaLoaded={handleMediaLoaded}
					calculatedPlayerHeight={calculatedPlayerHeight}
				/>
				{viewportWidth > 768 && 
					<PlayerDetails
						previousSection={detailNavigationSections.prev}
						nextSection={detailNavigationSections.next}
						subModule={data.SubModule}
						courseLink={data.Course}
						moduleLink={data.Module}
						handleClickSectionItem={handleClickSectionItem}
					/>
				}				
			</div>
			{sidebarSections.length > 0 &&
				<PlayerSidebar
					sectionsArray={sidebarSections}
					selectedSection={selectedSection}
					handleClickSectionItem={handleClickSectionItem}
					module={data.Module}
					submodule={data.SubModule}
				/>
			}
		</div>
	);
};

export default Player;
