import { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'components/Button/Button';
import useBranding from 'hooks/useBranding';
import './UserManagement.scss';
import Pod from 'components/Pod/Pod';
import DisplaySeats from './components/DisplaySeats/DisplaySeats';
import DataTable from 'components/DataTable/DataTable';
import ContentGroup from 'components/ContentGroup/ContentGroup';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import { useModal } from 'components/Modal/context/ModalContext';
import ModalContent from './components/ModalContent/ModalContent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { getFetchOptions } from '../../utils/fetch';
import useFetch from '../../hooks/useFetch';
import MessageModal, { MessageModalType } from '../../components/Modal/MessageModal';
import { DataFetcher } from '../../components/DataFetcher';

const UserManagement = () => {
	const { gradient, watermark } = useBranding();
	const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [hoveredGroupId, setHoveredGroupId] = useState(null);
	const [deleting, setDeleting] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const { execute, executingFetch } = useFetch();
	const getFetchData = { url: `/api/CompanyUserWithSeatInfo/?key=${refreshKey}` };
	const [data, setData] = useState(null);
	const [fetchData, setFetchData] = useState({});

	useEffect(() => {
		if (data) {
			console.log("Data fetched from API:", data);
		}
	}, [data]);

	const maxSeats = data?.MaxSeats || 0;
	const usedSeats = data?.UsedSeats || 0;
	const companyUsers = data?.CompanyUsers || [];
	const companyId = data?.CompanyId || 0;

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	useEffect(() => {
		// Clear the selectedUsers array when the component mounts
		setSelectedUsers([]);
	}, []);

	const nameTemplate = (rowData) => rowData.FullName;
	const emailTemplate = (rowData) => `${rowData.Username}`;

	// Template for DeviceGroup
	const deviceGroupTemplate = (rowData) => {
		if (rowData.DeviceGroups.length === 0) {
			return <p>No devices assigned</p>;
		}

		return rowData.DeviceGroup.map(group => (
			<span key={group.Id} className="device-group-item">
				{group.Name}
			</span>
		));
	};

	const handleRemoveGroup = (groupId, rowData) => {
		if (selectedUsers.length === 0) {
			// Remove the group from the clicked user
			const updatedUser = {
				...rowData,
				ContentGroups: rowData.ContentGroups.filter(group => group.Id !== groupId)
			};

			alert(`Removed group ${groupId} from ${rowData.FullName}`);
		} else {
			const updatedSelectedUsers = selectedUsers.map(user => {
				if (user.ContentGroups.some(group => group.Id === groupId)) {
					return {
						...user,
						ContentGroups: user.ContentGroups.filter(group => group.Id !== groupId)
					};
				}
				return user;
			});

			const selectedUserNames = selectedUsers.map(user => user.FullName).join(', ');
			alert(`Removed group ${groupId} from selected users: ${selectedUserNames}`);

			setSelectedUsers(updatedSelectedUsers);
		}
	};

	const handleMouseEnterGroup = (groupId) => {
		setHoveredGroupId(groupId);
	};

	const handleMouseLeaveGroup = () => {
		setHoveredGroupId(null);
	};

	const contentGroupsTemplate = (rowData) => {
		const isRowSelected = selectedUsers.some(user => user.Id === rowData.Id);
		const disableGroups = selectedUsers.length > 0 && !isRowSelected;

		if (rowData.ContentGroups.length === 0) {
			return <p>No groups assigned</p>;
		}

		return rowData.ContentGroups.map(group => (
			<ContentGroup
				key={group.Id}
				id={group.Id}
				name={group.Name}
				onRemove={() => handleRemoveGroup(group.Id, rowData)}
				disabled={disableGroups}
				hovered={isRowSelected && hoveredGroupId === group.Id && selectedUsers.length > 0}
				onMouseEnter={() => isRowSelected && handleMouseEnterGroup(group.Id)}
				onMouseLeave={() => isRowSelected && handleMouseLeaveGroup()}
			/>
		));
	};



	const editTemplate = (rowData) => {
		const isDisabled = selectedUsers.length > 0 && !selectedUsers.some(user => user.Id === rowData.Id);
		return (
			<DropdownButton text={"Edit"} primaryAction={() => handleOpenEditModal(rowData)} buttonMenu={getButtonMenu(rowData)} disabled={isDisabled} />
		);
	};

	const selectTemplate = (rowData) => (
		<Checkbox
			onChange={(e) => onSelectChange(e.checked, rowData)}
			checked={selectedUsers.some(user => user.Id === rowData.Id)}
		/>
	);

	const onSelectChange = (selected, user) => {
		if (selected) {
			setSelectedUsers(prevSelected => [...prevSelected, user]);
		} else {
			setSelectedUsers(prevSelected => prevSelected.filter(u => u.Id !== user.Id));
		}
	};

	const toggleSelectAll = (checked) => {
		setSelectAll(checked);
		if (checked) {
			setSelectedUsers(companyUsers);
		} else {
			setSelectedUsers([]);
		}
	};

	const resendRegistrationEmail = (clickedUser) => {
		const userSet = new Set(selectedUsers.map(user => user.Id));
		userSet.add(clickedUser.Id);

		const finalUserList = companyUsers.filter(user => userSet.has(user.Id));

		if (finalUserList.length === 0) {
			alert('No users selected.');
			return;
		}

		const selectedUsernames = finalUserList.map(user => user.Username);
		const options = getFetchOptions({
			method: "POST",
			body: selectedUsernames
		});
		setFetchData({ url: "/api/ResendCompleteRegistration/", options: options });
	};

	useEffect(() => {
		if (deleting) {
			const options = getFetchOptions({
				method: "DELETE",
				body: selectedUserIds
			});
			setFetchData({ url: "/api/CompanyUser/", options: options });
			setRefreshKey(prevKey => prevKey + 1);
		}
	}, [deleting, execute, selectedUserIds]);

	const deleteUser = (clickedUser) => {
		const userSet = new Set(selectedUsers.map(user => user.Id));
		userSet.add(clickedUser.Id);

		const finalUserList = companyUsers.filter(user => userSet.has(user.Id));

		if (finalUserList.length === 0) {
			alert('No users selected.');
			return;
		}

		setSelectedUserIds(finalUserList.map(user => user.Id));
		setConfirmDelete(true);
	};

	function onClickConfirmDelete(yes) {
		if (yes) {
			setDeleting(true);
		}
		setConfirmDelete(false);
	}

	const { openModal, closeModal } = useModal();
	const addModalTitle = "Add User";
	const modalBasicButton = false;

	const closeModalAndRefresh = () => {
		closeModal();
		setRefreshKey(prevKey => prevKey + 1);
	}

	const handleOpenAddModal = () => {
			openModal(addModalTitle, <ModalContent edit={false} companyId={companyId} onClose={closeModalAndRefresh} />, modalBasicButton);
	};

	const handleOpenEditModal = (clickedUser) => {
		const usersToEdit = selectedUsers.length > 0 ? selectedUsers : [clickedUser];
		if (usersToEdit.length === 0) {
			alert('No users selected.');
			return;
		}

		const editModalTitle = usersToEdit.length > 1 ? `Edit ${usersToEdit.length} Users` : "Edit User";

		openModal(editModalTitle, <ModalContent edit={true} companyId={companyId} users={usersToEdit} onClose={closeModalAndRefresh} />, modalBasicButton);
	};


	const getButtonMenu = (rowData) => {
		const menu = [
			{ label: 'Edit', action: () => handleOpenEditModal(rowData) },
			{ label: 'Resend Registration Email', action: () => resendRegistrationEmail(rowData) },
			{ label: 'Delete User', action: () => deleteUser(rowData) },
		];

		if (selectedUsers.length > 0) {
			menu.unshift({ label: `${selectedUsers.length} users selected` });
		}

		return menu;
	};

	const columns = [
		{ headerName: <Checkbox onChange={(e) => toggleSelectAll(e.checked)} checked={selectAll} />, valueGetter: selectTemplate, style: { width: '40px', paddingRight: '20px' }, sortable: false },
		{ field: 'FullName', headerName: 'Name', valueGetter: nameTemplate },
		{ field: 'Username', headerName: 'Email', valueGetter: emailTemplate },
		{ field: 'DeviceGroup', headerName: 'Device Group', valueGetter: deviceGroupTemplate, sortable: false },
		{ field: 'ContentGroups', headerName: 'Content Groups', valueGetter: contentGroupsTemplate, sortable: false },
		{ headerName: 'Edit', valueGetter: editTemplate, style: { width: '140px' }, sortable: false }
	];

	const rowClassName = (rowData) => {
		return selectedUsers.some(user => user.Id === rowData.Id) ? 'active' : '';
	};

	return (
		<div className="grid-full gutters">
			<DataFetcher fetchData={getFetchData} setData={setData} />
			<DataFetcher fetchData={fetchData} setData={data} />
			{executingFetch
				? <LoadingSpinner active={executingFetch} />
				: confirmDelete
					? <MessageModal title="Confirm User Deletion" type={MessageModalType.Question} onClick={onClickConfirmDelete} size="m">
						Are you sure you want to delete the selected Users?
					</MessageModal>
					: <Pod title="User Management" gradient={gradient} watermark={watermark}>
						<DisplaySeats maxSeats={maxSeats} usedSeats={usedSeats} />
						<div className="datatable-pod">
							<div className="datatable-pod-buttons">
								<Button text="Add User" onClick={handleOpenAddModal} />
							</div>
							<DataTable
								rows={companyUsers}
								columns={columns}
								rowsPerPage={10}
								rowClassName={rowClassName}
							/>
							<div className="datatable-pod-buttons">
								<Button text="Add User" onClick={handleOpenAddModal} />
							</div>
						</div>
					</Pod>}
		</div>
	);
};

export default UserManagement;